import React, { useEffect } from 'react';
import StagingCard from './StagingCard';
import { IStaging } from '../../models/staging.interface';
import { Grid, GridColumn, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IAppState } from '../../reducers';
import { loadStagings } from '../../store/staging/actions/staging.action';
import Pusher from 'pusher-js';
const pusher = new Pusher('a0bda5fb6b82057444a2', {
	cluster: 'ap4',
});
const STAGING_CHANNEL = 'staging-channel';

interface IProps {
	stagings: IStaging[];
	loadStagings: Function;
}

const StagingBoard: React.FC<IProps> = ({ stagings, loadStagings }) => {
	useEffect(() => {
		loadStagings();
		const channel = pusher.subscribe(STAGING_CHANNEL);

		channel.bind('update', function() {
			loadStagings();
		});

		return () => {
			channel.unbind('update');
		};
	}, [loadStagings]);
	if (!stagings) {
		return <Loader />;
	}
	return (
		<Grid stackable>
			{stagings.map(staging => (
				<GridColumn key={staging.key} mobile={16} tablet={8} computer={4}>
					<StagingCard staging={staging} />
				</GridColumn>
			))}
		</Grid>
	);
};

function mapStateToProps(state: IAppState) {
	return { stagings: state.stagings };
}

export default connect(
	mapStateToProps,
	{ loadStagings }
)(StagingBoard);
