import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createAppStore } from './store';
import 'reflect-metadata';
const store = createAppStore();
declare const module: any;

const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<Component />
		</Provider>,
		document.getElementById('root')
	);
};

render(App);
serviceWorker.unregister();

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}
