import { IUser } from './user.interface';

export interface IStaging {
	key: string;
	owner: IUser;
	status: StagingStatus;
	mode?: StagingMode;
	branch?: string;
	assignee?: IUser;
}

export enum StagingMode {
	FULL_TIME = 'full_time',
	HALF_TIME = 'half_time',
}

export enum StagingStatus {
	running = 'running',
	stopped = 'stopped',
	pending = 'pending',
	shuttingDown = 'shutting-down',
	terminated = 'terminated',
	stopping = 'stopping',
}
