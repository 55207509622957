import { ContainerInstance, Service } from 'typedi';
import axios, { AxiosInstance } from 'axios';
import { config } from '../../config';
import { CurrentUser } from '../../types';

@Service()
export class ApiService {
	constructor(private readonly container: ContainerInstance) {}

	private _api: AxiosInstance;

	api() {
		if (!this._api) {
			this._api = axios.create({
				baseURL: config.get('apiServer'),
			});
		}
		const currentUser = this.container.get('currentUser') as CurrentUser;
		this._api.defaults.headers.Authorization = currentUser.id_token;
		return this._api;
	}
}
