import React, { useEffect } from 'react';
import './App.scss';
import StagingBoard from './components/staging/StagingBoard';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import NotificationContainer from './components/notifications/NotificationContainer';
import { IAppState } from './reducers';
import { signIn } from './store/auth/actions/auth.action';
import Header from './components/shared/Header';
import ConfirmationManager from './components/shared/ConfirmationManager';
import EventModalManager from './components/shared/EventModalManager';
import { CurrentUser } from './types';
import { config } from './config';

interface IProps {
	signIn: (handleCredentialResponse: CredentialResponse) => void;
	currentUser: CurrentUser;
}

const App: React.FC<IProps> = ({ signIn, currentUser }) => {
	// google setup
	useEffect(() => {
		let interval;
		// INT-2379: Don't need to recheck if google has loaded if we already have currentUser
		if (!!currentUser) {
			return;
		}
		(async () => {
			interval = setInterval(() => {
				if (window.google) {
					window.google.accounts.id.initialize({
						client_id: config.get('googleClientId'),
						callback: signIn,
						auto_select: true,
						ux_mode: 'popup',
					});

					window.google.accounts.id.prompt();

					if (interval) {
						clearInterval(interval);
					}
				}
			}, 2000);
		})();

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [currentUser, signIn]);

	if (currentUser === null) {
		return (
			<div>
				<div>You must log in</div>
			</div>
		);
	} else if (currentUser === undefined) {
		return (
			<Dimmer inverted active className={'full-screen'}>
				<Loader inverted indeterminate>
					Loading
				</Loader>
			</Dimmer>
		);
	}
	return (
		<>
			<header>
				<LoadingBar />
			</header>
			<Header />
			<Container className={'pad-top-30'}>
				<StagingBoard />
				<NotificationContainer />
			</Container>
			<EventModalManager />
			<ConfirmationManager />
		</>
	);
};

function mapStateToProps(state: IAppState) {
	return { currentUser: state.auth.currentUser };
}
export default connect(
	mapStateToProps,
	{ signIn }
)(App);
