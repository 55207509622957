import { ApiService } from '../shared/api.service';
import { Service, Inject } from 'typedi';

@Service()
export class IssueService {
	@Inject(type => ApiService)
	private apiService: ApiService;

	async getIssueDetails({ includeBranches, issues = [] }: { includeBranches: boolean; issues: string[] }) {
		if (!issues.length) {
			return {};
		}
		const params = new URLSearchParams();
		issues.forEach(issue => params.append('issues', issue));
		if (includeBranches) {
			params.append('showBranches', 'true');
		}

		const { data: issueDetails } = await this.apiService.api().get('/issues-details', {
			params,
		});

		return issueDetails;
	}
}
