export const LOAD_STAGINGS = 'LOAD_STAGINGS';
export const LOAD_STAGINGS_FULFILLED = 'LOAD_STAGINGS_FULFILLED';
export const CLAIM_STAGING = 'CLAIM_STAGING';
export const TOGGLE_STAGING = 'TOGGLE_STAGING';
export const CONTROL_STAGING = 'CONTROL_STAGING';
export const CHANGE_STAGING_MODE = 'CHANGE_STAGING_MODE';

export const actions = {
	LOAD_BRANCH: 'LOAD_BRANCH',
	BUILD_GRUNT: 'BUILD_GRUNT',
	RESTART_APACHE: 'RESTART_APACHE',
	RESET_A_PASSWORD: 'RESET_A_PASSWORD',
	RESET_ALL_PASSWORDS: 'RESET_ALL_PASSWORDS',
	DOWNLOAD_DEPLOYMENT: 'DOWNLOAD_DEPLOYMENT',
	INDEX_ELASTICSEARCH: 'INDEX_ELASTICSEARCH',
	REBUILD_ELASTICSEARCH: 'REBUILD_ELASTICSEARCH',
	GENERATE_PDF: 'GENERATE_PDF',
	RUN_WEB_APP_AUTOMATION_TEST: 'RUN_WEB_APP_AUTOMATION_TEST',
	RUN_API_AUTOMATION_TEST: 'RUN_API_AUTOMATION_TEST',
};
