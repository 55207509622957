export function getJiraFromBranch(branch) {
	if (!branch) {
		return '';
	}
	const matches = /([A-Z0-9]+?-\d+)/.exec(branch);
	if (!matches) {
		return '';
	}
	return matches[1];
}
