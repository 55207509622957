const configMap = {
	development: {
		googleClientId: '235433993534-o2jknndnmht12ql39to2vprn5qvk62vd.apps.googleusercontent.com',
		apiServer: 'https://n06mo1pn44.execute-api.us-west-2.amazonaws.com/prod/',
	},
	production: {
		googleClientId: '235433993534-o2jknndnmht12ql39to2vprn5qvk62vd.apps.googleusercontent.com',
		apiServer: 'https://n06mo1pn44.execute-api.us-west-2.amazonaws.com/prod/',
	},
};

export const config = {
	get(key) {
		return configMap[process.env.NODE_ENV][key];
	},
};
