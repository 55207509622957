import { createStore, applyMiddleware, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import { reducers, IAppState } from './reducers';
import { createReducerManager, ReducerManager } from './utils/redux/reducer-manager';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise-middleware';
import { errorMiddleware } from './utils/redux/middlewares/error';
import { Container } from 'typedi';
type ALStore = Store<IAppState> & { reducerManager: ReducerManager };

export function createAppStore() {
	const reducerManager = createReducerManager(reducers);

	const middlewares = applyMiddleware(
		reduxThunk.withExtraArgument({ container: Container }),
		errorMiddleware(),
		promise,
		loadingBarMiddleware()
	);

	const enhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools(middlewares) : middlewares;
	const store = createStore(reducerManager.reduce, enhancers) as ALStore;
	store.reducerManager = reducerManager;

	return store;
}
