import React, { useContext } from 'react';
import { IStaging, StagingMode, StagingStatus } from '../../models/staging.interface';
import { Card, Checkbox, Grid, GridColumn, GridRow, Header, Image, Label } from 'semantic-ui-react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import halfModeSvg from '../../images/half-mode.svg';
import fullModeSvg from '../../images/full-mode.svg';
import { connect } from 'react-redux';
import { Gravatar } from '../shared/Gravatar';
import jiraTicketSvg from '../../images/jira.svg';
import gitBranchSvg from '../../images/git-branch.svg';
import { getJiraFromBranch } from '../../utils/jira';
import { IUser } from '../../models/user.interface';
import { ContainerContext } from '../../utils/container.context';
import { Container } from 'typedi';
import { EventDispatcher } from '../../utils/event-dispatcher';
import { SHOW_CONFIRM, SHOW_MODAL } from '../shared/event.constants';
import { changeStagingMode, claimStaging, toggleStaging } from '../../store/staging/actions/staging.action';
import { SHOW_STAGING_CONTROL } from '../shared/modal.constants';
import { CurrentUser } from '../../types';

interface IProps {
	staging: IStaging;
	currentUser: CurrentUser;
	claimStaging: ({ staging: string, isClaim: boolean }) => void;
	changeStagingMode: ({ staging: string, stagingMode: StagingMode }) => void;
	toggleStaging: ({ staging: string, isOn: boolean }) => void;
}

const StagingCard: React.FC<IProps> = ({ staging, currentUser, claimStaging, toggleStaging, changeStagingMode }) => {
	const container: typeof Container = useContext(ContainerContext);
	return (
		<Card className="centered aligned" onClick={onCardClicked}>
			<Card.Content>
				<Card.Header>
					<Header style={{ minHeight: '40px' }} as="h4" className="flex flex-align-center flex-justify-between">
						{staging.owner && (
							<span data-variation="mini" data-inverted="" data-tooltip={staging.owner.name} className="image">
								<Gravatar circular email={staging.owner.email} />
							</span>
						)}
						<span className="marg-left-10">{staging.key}</span>
						<div className="right floated marg-top-2 flex flex-align-center">
							{(staging.status === StagingStatus.running || staging.mode) && (
								<Image
									circular
									inline
									size="mini"
									className="marg-right-10"
									disabled={staging.status !== StagingStatus.running}
									onClick={handleModeChangeClicked}
									src={staging.mode === StagingMode.FULL_TIME ? fullModeSvg : halfModeSvg}
								/>
							)}
							<Checkbox
								toggle
								checked={staging.status === StagingStatus.running}
								disabled={[StagingStatus.pending, StagingStatus.stopping].includes(staging.status)}
								readOnly={true}
								onClick={handleToggleClicked}
							/>
						</div>
					</Header>
				</Card.Header>
			</Card.Content>
			<Card.Content extra>
				<Card.Description style={{ minHeight: '135px' }}>
					{renderBranch(staging.branch, staging.assignee)}
				</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<div className={'flex flex-align-center flex-justify-between'}>
					<Button
						{...(isCurrentUser(staging.owner, currentUser) ? { negative: true } : { primary: true })}
						className="tiny"
						onClick={handleClaimClicked}
					>
						{isCurrentUser(staging.owner, currentUser) ? 'Unclaim' : 'Claim'}
					</Button>
					{![StagingStatus.running, StagingStatus.stopped].includes(staging.status) && (
						<div style={{ color: getColor(staging.status) }}>{staging.status}</div>
					)}
				</div>
			</Card.Content>
		</Card>
	);

	function getClaimContent() {
		if (isCurrentUser(staging.owner, currentUser)) {
			return `Do you want to release ${staging.key}?`;
		} else if (staging.owner) {
			return `Staging ${staging.key} belongs to ${staging.owner.name}. Do you still want to claim it?`;
		}
	}

	function handleClaimClicked() {
		const isUnclaiming = isCurrentUser(staging.owner, currentUser);
		if (isUnclaiming || staging.owner) {
			const eventDispatcher = container.get(EventDispatcher);
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: getClaimContent(),
				onConfirm: close => {
					handleClaim(!isUnclaiming);
					close();
				},
			});
		} else {
			handleClaim(!isUnclaiming);
		}
	}

	function handleClaim(isClaim) {
		claimStaging({ staging: staging.key, isClaim });
	}

	function handleModeChangeClicked() {
		if (!['running', 'stopped'].includes(staging.status)) {
			return;
		}
		const eventDispatcher = container.get(EventDispatcher);
		if (staging.status === 'stopped') {
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: `Do you want to fully disable this staging?`,
				onConfirm: close => {
					handleModeChange(null);
					close();
				},
			});
			return;
		}

		if (staging.mode === StagingMode.FULL_TIME) {
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: `Do you want to run staging ${staging.key} from 8am - 8pm (AEST) ?`,
				onConfirm: close => {
					handleModeChange(StagingMode.HALF_TIME);
					close();
				},
			});
		} else {
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: `Do you want to run staging ${staging.key} 24/7 ?`,
				onConfirm: close => {
					handleModeChange(StagingMode.FULL_TIME);
					close();
				},
			});
		}
	}

	function handleModeChange(newMode: StagingMode | null) {
		changeStagingMode({ staging: staging.key, stagingMode: newMode });
	}

	function handleToggleClicked() {
		const eventDispatcher = container.get(EventDispatcher);
		if (staging.status === 'running') {
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: `Do you want to turn off ${staging.key} ?`,
				onConfirm: close => {
					handleToggle(false);
					close();
				},
			});
		} else {
			eventDispatcher.trigger(SHOW_CONFIRM, {
				content: `Do you want to turn on ${staging.key} ?`,
				onConfirm: close => {
					handleToggle(true);
					close();
				},
			});
		}
	}

	function handleToggle(isOn: boolean) {
		toggleStaging({ staging: staging.key, isOn });
	}

	function isCurrentUser(owner: IUser, currentUser) {
		return owner && currentUser.userObject.email === owner.email;
	}

	function onCardClicked(event) {
		if (event.target.onclick) {
			return;
		}
		if (event.target.closest('.ui.checkbox')) {
			return;
		}
		const eventDispatcher = container.get(EventDispatcher);
		eventDispatcher.trigger(SHOW_MODAL, {
			popupState: { staging: staging.key },
			modalName: SHOW_STAGING_CONTROL,
		});
	}

	function renderBranch(branch, assignee) {
		if (!branch) {
			return <div>No branch found</div>;
		}
		const jira = getJiraFromBranch(branch);
		return (
			<Grid columns={2}>
				<GridRow>
					<GridColumn width={4}>
						<Image circular inline size="tiny" className="marg-right-10" src={gitBranchSvg} />
					</GridColumn>
					<GridColumn width={12} stretched>
						<Label
							data-tooltip={branch}
							data-position="bottom center"
							data-inverted=""
							className="flex flex-align-center"
						>
							<span className="truncate">{branch}</span>
						</Label>
					</GridColumn>
				</GridRow>{' '}
				{jira && (
					<GridRow>
						<GridColumn width={4}>
							<Image circular inline size="tiny" className="marg-right-10" src={jiraTicketSvg} />
						</GridColumn>
						<GridColumn width={12} stretched>
							<Label
								className="flex flex-align-center"
								data-inverted
								data-tooltip={assignee ? assignee.name : 'Unassigned'}
							>
								{assignee ? <Gravatar circular email={assignee.email} /> : null}
								<span className="marg-left-10">{jira}</span>
							</Label>
						</GridColumn>
					</GridRow>
				)}
			</Grid>
		);
	}

	function getColor(status) {
		if ([StagingStatus.pending, StagingStatus.running].includes(status)) {
			return 'orange';
		}
		return 'red';
	}
};

export default connect(
	({ auth }) => ({ currentUser: auth.currentUser }),
	{ claimStaging, changeStagingMode, toggleStaging }
)(StagingCard);
