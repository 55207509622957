import { isPromise } from '../../promise';
import { error } from 'react-notification-system-redux';

export function errorMiddleware() {
	return ({ dispatch }) => next => action => {
		if (!isPromise(action.payload)) {
			return next(action);
		}

		return next(action).catch(e => {
			console.error(e);
			dispatch(
				error({
					position: 'tr',
					title: 'Oops! Something went wrong :(',
					message: e && e.message ? e.message : e,
				})
			);
			return e;
		});
	};
}
