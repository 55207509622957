import { AnyAction } from 'redux';
import { CurrentUser } from '../../../types';
import { SIGNIN_FULFILLED, SIGNOUT } from '../actions/auth.constants';

export interface IState {
	currentUser?: CurrentUser;
}

export function authReducer(state: IState = {}, action: AnyAction) {
	switch (action.type) {
		case SIGNIN_FULFILLED:
			return { ...state, currentUser: action.payload };
		case SIGNOUT:
			return { ...state, currentUser: null };
		default:
			return state;
	}
}
