import React from 'react';
import { Header, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { FormField } from '../shared/FormField';
import { validateInteger, validateRequired } from '../../utils/validation';
import { actions } from '../../store/staging/actions/staging.constants';
import { controlStaging } from '../../store/staging/actions/staging.action';
import { connect } from 'react-redux';

const PDFTab: React.FC<any> = function({ staging, controlStaging }) {
	return (
		<>
			<Header as="h3">Generate PDF</Header>
			<Formik
				initialValues={{
					type: 'account_invoice',
					deployment: 'bananastest',
					staffId: '',
					objectId: '',
					objectTable: 'account_invoice',
				}}
				enableReinitialize={true}
				isInitialValid={false}
				onSubmit={handlePDFGeneration}
				render={props => {
					return (
						<Form onSubmit={props.handleSubmit}>
							<FormField
								name="deployment"
								component={Form.Input}
								componentProps={{
									label: 'Deployment',
									placeholder: 'bananastest',
								}}
								validate={validateRequired}
							/>
							<FormField
								name="staffId"
								component={Form.Input}
								componentProps={{
									label: 'Staff Id',
								}}
								validate={validateInteger}
							/>
							<FormField
								name="objectTable"
								component={Form.Select}
								componentProps={{
									label: 'Type',
									options: [
										{
											value: 'account_invoice',
											key: 'ac',
											text: 'Invoice',
										},
										{
											value: 'quote',
											key: 'q',
											text: 'Quote',
										},
										{
											value: 'account_purchase',
											key: 'ap',
											text: 'Purchase',
										},
										{
											value: 'job',
											key: 'j',
											text: 'Job Plan',
										},
									],
								}}
								validate={validateRequired}
							/>
							<FormField
								name="objectId"
								component={Form.Input}
								componentProps={{
									label: 'Object Id',
								}}
								validate={validateInteger}
							/>
							<Form.Button type="submit" primary disabled={!props.isValid || props.isSubmitting}>
								Generate
							</Form.Button>
						</Form>
					);
				}}
			/>
		</>
	);

	function handlePDFGeneration({ objectTable, objectId, deployment, staffId }) {
		return controlStaging({
			staging,
			action: {
				type: actions.GENERATE_PDF,
				deployment: deployment.endsWith('accelo.com') ? deployment : `${deployment}.${staging}.staging.accelo.com`,
				objecttable: objectTable,
				objectid: objectId,
				staffid: staffId,
			},
		});
	}
};

export default connect(
	null,
	{ controlStaging }
)(PDFTab);
