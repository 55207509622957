export function validateRequired(value) {
	return value && value.trim() !== '' ? '' : 'This field is required';
}

export function validateInteger(value) {
	return value == null || !/^\d+$/.test(value) ? 'Not a valid number' : '';
}

export function allowAll() {
	return '';
}
