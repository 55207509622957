import { Image, ImageProps } from 'semantic-ui-react';
import React from 'react';
import { md5 } from '../../utils/crypto';

interface IProps extends ImageProps {
	email: string;
}
const GravatarComponent: React.FC<IProps> = props => {
	return <Image {...props} src={getGravatarSource(props.email)} />;
};

function getGravatarSource(email = '') {
	return `https://www.gravatar.com/avatar/${md5(email)}?d=monsterid`;
}

export const Gravatar = GravatarComponent;
