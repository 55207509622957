import React, { useContext, useEffect } from 'react';
import { render } from 'react-dom';
import { ContainerContext } from '../../utils/container.context';
import { Container } from 'typedi';
import { EventDispatcher } from '../../utils/event-dispatcher';
import { SHOW_MODAL } from './event.constants';
import ControlModal from '../controls/ControlModal';
import ModalWrapper from './ModalWrapper';
import { SHOW_STAGING_CONTROL } from './modal.constants';
import { Provider, ReactReduxContext } from 'react-redux';

const modalMapping = {
	[SHOW_STAGING_CONTROL]: ControlModal,
};

const EventModalManager: React.FC = function() {
	const container: typeof Container = useContext(ContainerContext);
	const { store } = useContext(ReactReduxContext);

	useEffect(() => {
		const eventDispatcher = container.get(EventDispatcher);
		return eventDispatcher.on(SHOW_MODAL, (event, { popupState, modalName }) => {
			if (!modalMapping[modalName]) {
				return;
			}
			const component = modalMapping[modalName];
			const element = document.createElement('div');
			const mountPoint = document.querySelector('#modal-container');
			mountPoint!!.appendChild(element);

			render(
				<Provider store={store}>
					<ModalWrapper
						popupState={popupState}
						onClose={() => mountPoint!!.removeChild(element)}
						component={component}
					/>
				</Provider>,
				element
			);
		});
	}, [container, store]);

	return <div id="modal-container" />;
};

export default EventModalManager;
