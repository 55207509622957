import React, { useState } from 'react';
import { Divider, Form, Header, Segment, Tab, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { FormField } from '../shared/FormField';
import { validateRequired } from '../../utils/validation';
import { connect } from 'react-redux';
import { actions } from '../../store/staging/actions/staging.constants';
import { controlStaging } from '../../store/staging/actions/staging.action';

const ElasticsearchTab: React.FC<any> = function({ staging, controlStaging }) {
	const [isRebuildingIndex, setIsRebuildingIndex] = useState(false);
	const indexPanes = [
		{
			menuItem: 'Basic',
			render: () => <Tab.Pane>{renderBasicPane()}</Tab.Pane>,
		},
		{
			menuItem: 'Advanced',
			render: () => <Tab.Pane>{renderAdvancedPane()}</Tab.Pane>,
		},
	];
	return (
		<>
			<Header as="h3">Rebuild index</Header>
			<Button primary onClick={handleRebuildIndex} disabled={isRebuildingIndex}>
				Rebuild
			</Button>
			<Divider hidden />
			<Header as="h3">Indexing</Header>
			<Tab menu={{ secondary: true, pointing: true }} panes={indexPanes} />
		</>
	);

	function handleBasicIndex({ deployment, table, count }) {
		controlStaging({
			staging,
			action: {
				type: actions.INDEX_ELASTICSEARCH,
				deployment,
				query: count.toString().startsWith('#') ? `${table} ${count}` : `${count} x ${table}`,
			},
		});
	}

	function handleAdvancedIndex({ deployment, query }) {
		return controlStaging({
			staging,
			action: {
				type: actions.INDEX_ELASTICSEARCH,
				deployment,
				query,
			},
		});
	}

	async function handleRebuildIndex() {
		try {
			setIsRebuildingIndex(true);
			await controlStaging({
				staging,
				action: {
					type: actions.REBUILD_ELASTICSEARCH,
				},
			});
		} finally {
			setIsRebuildingIndex(false);
		}
	}

	function validateSearchQuery(value) {
		if (/^\d+\s*x\s*(\^?\w+|\.\*)$/.test(value)) {
			return '';
		}
		if (/^\w+\s#\d+$/.test(value)) {
			return '';
		}
		return 'Not a valid query';
	}

	function validateCount(value) {
		if (/^#\d+$/.test(value)) {
			return '';
		}

		if (/^\d+$/.test(value)) {
			return '';
		}
		return 'Must be #ID or number of items';
	}

	function renderBasicPane() {
		return (
			<Formik
				enableReinitialize={true}
				initialValues={{
					deployment: 'bananastest',
					table: '.*',
					count: 10,
				}}
				isInitialValid={true}
				onSubmit={handleBasicIndex}
				render={props => {
					return (
						<Form onSubmit={props.handleSubmit}>
							<Header as="h5">Guide</Header>
							<FormField
								name="deployment"
								component={Form.Input}
								componentProps={{
									label: 'Deployment',
									placeholder: 'bananastest',
								}}
								validate={validateRequired}
							/>
							<FormField
								name="count"
								component={Form.Input}
								componentProps={{
									label: 'Count or ID',
									placeholder: '#10 for ID or 10 for Count',
								}}
								validate={validateCount}
							/>
							<FormField
								name="table"
								component={Form.Dropdown}
								componentProps={{
									label: 'Table',
									fluid: true,
									search: true,
									selection: true,
									options: getTableOptions(),
								}}
							/>
							<Form.Button type="submit" primary disabled={!props.isValid || props.isSubmitting}>
								Index
							</Form.Button>
						</Form>
					);
				}}
			/>
		);
	}

	function renderAdvancedPane() {
		return (
			<Formik
				initialValues={{
					deployment: 'bananastest',
					query: '10 x .*',
				}}
				enableReinitialize={true}
				isInitialValid={true}
				onSubmit={handleAdvancedIndex}
				render={props => {
					return (
						<Form onSubmit={props.handleSubmit}>
							<Header as="h5">Guide</Header>
							<Segment className="preserve-whitespace" inverted>
								{`Index N of a given type. e.g, "10 x company" for 10 companies
> {COUNT} x {TABLE}

Index N of all types
> {COUNT} x .*

Index a specific object (e.g, "company #10" for company with id 10)
> {TABLE} #{ID}

Index N of all types starting with company
> {COUNT} x ^company`}
							</Segment>
							<FormField
								name="deployment"
								component={Form.Input}
								componentProps={{
									label: 'Deployment',
									placeholder: 'bananastest',
								}}
								validate={validateRequired}
							/>
							<FormField
								name="query"
								component={Form.Input}
								componentProps={{
									label: 'Query',
									placeholder: '10 x .*',
								}}
								validate={validateSearchQuery}
							/>
							<Form.Button type="submit" primary disabled={!props.isValid || props.isSubmitting}>
								Index
							</Form.Button>
						</Form>
					);
				}}
			/>
		);
	}
};

function getTableOptions() {
	return [
		{ value: '.*', text: 'All' },
		{ value: 'activity', text: 'Activity' },
		{ value: 'activity_thread', text: 'Activity Thread' },
		{ value: 'task', text: 'Task' },
		{ value: 'issue', text: 'Issue' },
		{ value: 'company', text: 'Company' },
		{ value: 'contact', text: 'Contact' },
		{ value: 'affiliation', text: 'Affiliation' },
		{ value: 'prospect', text: 'Prospect' },
		{ value: 'campaign', text: 'Campaign' },
		{ value: 'contract', text: 'Contract' },
		{ value: 'contract_period', text: 'Contract Period' },
		{ value: 'job', text: 'Job' },
		{ value: 'milestone', text: 'Milestone' },
		{ value: 'account_invoice', text: 'Invoice' },
		{ value: 'account_purchase', text: 'Purchase' },
		{ value: 'resource', text: 'Resource' },
		{ value: 'expense', text: 'Expense' },
		{ value: 'quote', text: 'Quote' },
		{ value: 'client_portal_signoff', text: 'Sign-off' },
		{ value: 'staff', text: 'Staff' },
		{ value: 'request', text: 'Request' },
		{ value: 'asset', text: 'Asset' },
		{ value: 'asset_link', text: 'Asset Link' },
		{ value: 'deployment', text: 'Deployment' },
		{ value: 'address', text: 'Address' },
		{ value: 'custom_locations', text: 'Locations' },
		{ value: 'timeline_entry', text: 'Timeline entry' },
		{ value: 'site', text: 'Site' },
		{ value: 'company_campaign_events', text: 'Campaign events' },
		{ value: 'campaign_action_target_event', text: 'Campaign target events' },
		{ value: 'filter', text: 'Filter' },
		{ value: 'groups', text: 'Groups' },
		{ value: 'skill', text: 'Skill' },
	].sort((a, b) => a.text.localeCompare(b.text));
}

export default connect(
	null,
	{
		controlStaging,
	}
)(ElasticsearchTab);
