import React from 'react';
import { connect } from 'react-redux';
import { Menu, Image, Header as Heading } from 'semantic-ui-react';
import { signOut } from '../../store/auth/actions/auth.action';
import { CurrentUser } from '../../types';
import { Gravatar } from './Gravatar';

interface IProps {
	currentUser: CurrentUser;
	signOut: Function;
}

const Header: React.FC<IProps> = function({ currentUser, signOut }) {
	if (!currentUser) {
		return null;
	}
	return (
		<Menu borderless stackable className="no-margin">
			<Menu.Item>
				<Image size="tiny" src={require('../../logo.svg').default} />
			</Menu.Item>
			<Menu.Item>
				<Heading size="medium">Staging Control App</Heading>
			</Menu.Item>
			<Menu.Menu position="right">
				<Menu.Item>
					<Gravatar size="mini" circular email={currentUser.userObject.email} />
					<span className="marg-left-10">{currentUser.userObject.name}</span>
				</Menu.Item>
				<Menu.Item onClick={() => signOut()}>Sign out</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

export default connect(
	({ auth }) => ({ currentUser: auth.currentUser }),
	{ signOut }
)(Header);
