import { SIGNIN_FULFILLED, SIGNOUT } from './auth.constants';
import { Container } from 'typedi';
import jwt_decode from 'jwt-decode';

// INT-2379: Note: there are more properties in the jwt payload
type SignInJwtPayload = {
	email: string;
	name: string;
	family_name: string;
	given_name: string;
};

export function signIn(response) {
	return async (dispatch, getState, { container }: { container: typeof Container }) => {
		const { email, name, family_name, given_name } = jwt_decode(response.credential) as SignInJwtPayload;
		const userObject = {
			email: email,
			name: name,
			given_name: given_name,
			family_name: family_name,
		};

		return dispatch({
			type: SIGNIN_FULFILLED,
			payload: () => {
				container.set('currentUser', { id_token: response.credential, userObject });
				return { id_token: response.credential, userObject };
			},
		});
	};
}

export function signOut() {
	return async (dispatch, getState, { container }: { container: typeof Container }) => {
		dispatch({
			type: SIGNOUT,
		});
		if (window && window.google) {
			// INT-2379: Was recommended by docs so that it won't keep looping login
			window.google.accounts.id.disableAutoSelect();
		}
		window.location.reload();
	};
}
