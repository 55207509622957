import {
	CHANGE_STAGING_MODE,
	CLAIM_STAGING,
	CONTROL_STAGING,
	LOAD_STAGINGS,
	TOGGLE_STAGING,
} from './staging.constants';
import { StagingService } from '../../../services/staging/staging.service';
import { Container } from 'typedi';
import { success } from 'react-notification-system-redux';
import { StagingMode } from '../../../models/staging.interface';

export function loadStagings() {
	return (dispatch, getState, { container }: { container: typeof Container }) => {
		dispatch({
			type: LOAD_STAGINGS,
			payload: container.get(StagingService).getStagings(),
		});
	};
}

export function toggleStaging({ staging, isOn }) {
	return async (dispatch, getState, { container }: { container: typeof Container }) => {
		await dispatch({
			type: TOGGLE_STAGING,
			payload: container.get(StagingService).toggleStaging({ isOn, staging }),
		});
		dispatch(showRequestSuccess(`Your request to turn ${isOn ? 'on' : 'off'} ${staging} has been sent`));
	};
}

export function changeStagingMode({ staging, stagingMode }) {
	return async (dispatch, getState, { container }: { container: typeof Container }) => {
		await dispatch({
			type: CHANGE_STAGING_MODE,
			payload: container.get(StagingService).changeStagingMode({ stagingMode, staging }),
		});
		if (stagingMode) {
			dispatch(
				showRequestSuccess(
					`Your request to change ${staging} to ${
						stagingMode === StagingMode.FULL_TIME ? '24/7' : '8am - 8 pm'
					}  has been sent`
				)
			);
		} else {
			dispatch(showRequestSuccess(`Your request to disable ${staging} has been sent`));
		}
	};
}

export function claimStaging({ staging, isClaim }) {
	return async (dispatch, getState, { container }: { container: typeof Container }) => {
		await dispatch({
			type: CLAIM_STAGING,
			payload: container.get(StagingService).claimStaging({ isClaim, staging }),
		});
	};
}

export function controlStaging({ staging, action }) {
	return (dispatch, getState, { container }: { container: typeof Container }) => {
		return dispatch({
			type: CONTROL_STAGING,
			payload: container
				.get(StagingService)
				.controlStaging({ action, staging })
				.then(() => {
					dispatch(showRequestSuccess(`Your request has been sent`));
				}),
		});
	};
}

function showRequestSuccess(message) {
	return success({
		position: 'tr',
		title: 'Success',
		message,
	});
}
