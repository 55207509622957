import { AnyAction } from 'redux';
import { IStaging } from '../../../models/staging.interface';
import { LOAD_STAGINGS_FULFILLED } from '../actions/staging.constants';

export function stagingReducer(state: IStaging[] = [], action: AnyAction) {
	switch (action.type) {
		case LOAD_STAGINGS_FULFILLED:
			return [...action.payload];
		default:
			return state;
	}
}
