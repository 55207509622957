import { Service, Inject, ContainerInstance } from 'typedi';
import { StagingMode } from '../../models/staging.interface';
import { ApiService } from '../shared/api.service';
import { ucfirst } from '../../utils/string';
import { getJiraFromBranch } from '../../utils/jira';
import { IssueService } from '../issues/issue.service';

@Service()
export class StagingService {
	@Inject(type => ApiService)
	private apiService: ApiService;

	@Inject(type => IssueService)
	private issueService: IssueService;

	toggleStaging({ isOn, staging }: { isOn: boolean; staging: string }) {
		return this.apiService.api().post(`/staging_servers/${staging}/switch`, {
			isOn,
		});
	}
	changeStagingMode({ staging, stagingMode }: { stagingMode: StagingMode; staging: string }) {
		return this.apiService.api().post(`/staging_servers/${staging}/mode`, {
			mode: stagingMode,
		});
	}
	controlStaging({ action, staging }: { action: any; staging: string }) {
		return this.apiService.api().post(`/staging_servers/${staging}/controls`, {
			action,
		});
	}
	claimStaging({ isClaim, staging }: { isClaim: boolean; staging: string }) {
		return this.apiService.api().post(`/staging_servers/${staging}/claim`, {
			isClaim,
		});
	}

	async getStagings() {
		let {
			data: { stagings },
		} = await this.apiService.api().get('/staging_servers');

		const issueMap: { [key: string]: string } = stagings.reduce(
			(currentMap, staging) => ({
				...currentMap,
				[staging.key]: getJiraFromBranch(staging.branch),
			}),
			{}
		);

		const issues: string[] = Object.values(issueMap).filter(issue => issue);

		if (issues.length) {
			const issueDetails = await this.issueService.getIssueDetails({
				includeBranches: false,
				issues,
			});

			stagings = stagings.map(staging => {
				if (!issueMap[staging.key]) {
					return staging;
				}
				const issue = issueMap[staging.key];
				if (!(issueDetails[issue] && issueDetails[issue].assignee)) {
					return staging;
				}
				return {
					...staging,
					assignee: {
						name: issueDetails[issue].assignee.displayName,
						email: issueDetails[issue].assignee.email,
					},
				};
			});
		}

		stagings.sort((a, b) => {
			const aPriority = getPriority(a.key);
			const bPriority = getPriority(b.key);

			if (aPriority !== bPriority) {
				return aPriority > bPriority ? -1 : 1;
			}
			return a.key.localeCompare(b.key, undefined, { numeric: true });
		});

		return stagings.map(staging => ({
			...staging,
			owner: staging.owner
				? {
						name: getName(staging.owner),
						email: staging.owner,
				  }
				: null,
		}));
	}
}

function getName(email) {
	return email
		.split('@')[0]
		.split('.')
		.map(namePart => ucfirst(namePart))
		.join(' ');
}

function getPriority(key) {
	if (/stg[1-9][0-9]?/.test(key)) {
		return 10;
	}
	return 1;
}
