import React from 'react';
import { Field, getIn, FieldConfig } from 'formik';

interface IProps extends FieldConfig {
	component: any;
	componentProps: any;
	onChange?: (e: any, { name: string, value: any }) => void;
	onBlur?: (e: any) => void;
}

const getFormikFieldError = (form, field) => {
	const { name } = field;
	const touched = getIn(form.touched, name);
	return touched && getIn(form.errors, name);
};

const setFormikFieldValue = (form, name, value, shouldValidate) => {
	form.setFieldValue(name, value, shouldValidate);
	form.setFieldTouched(name, true, shouldValidate);
};

export const FormField: React.FC<IProps> = ({
	component,
	componentProps = {},
	onChange,
	onBlur,
	...fieldProps
}: any) => (
	<Field {...fieldProps}>
		{props => {
			let { id } = componentProps;
			const { field, form } = props;
			const { value } = field;
			const error = getFormikFieldError(form, field);
			componentProps.id = id;
			componentProps.error = error;
			const valueProps = typeof value === 'boolean' ? { checked: value, value: '' } : { value: value || '' };
			return React.createElement(component, {
				...componentProps,
				...field,
				...props,
				...valueProps,
				onChange: (e, { name, value, checked }) => {
					// eslint-disable-next-line
					if (checked != null && (value === '' || value == undefined)) {
						value = !!checked;
					}

					setFormikFieldValue(form, name, value, true);
					if (onChange) {
						onChange(e, { name, value });
					}
				},
				onBlur: e => {
					form.handleBlur(e);
					if (onBlur) {
						onBlur(e);
					}
				},
			});
		}}
	</Field>
);
