import React from 'react';
import { Header, Divider, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { FormField } from '../shared/FormField';
import { allowAll, validateRequired } from '../../utils/validation';
import { actions } from '../../store/staging/actions/staging.constants';
import { connect } from 'react-redux';
import { controlStaging } from '../../store/staging/actions/staging.action';

const DeploymentTab: React.FC<any> = function({ staging, controlStaging }) {
	return (
		<>
			{/*this requires some effort to make the SSM command for migrating deployment work*/}
			{/*<Header as="h3">Download deployment (Requires deployments to be exported)</Header>
			<Formik
				initialValues={{
					deployment: 'bananastest',
				}}
				isInitialValid={true}
				onSubmit={handleDownloadDeployment}
				render={props => {
					return (
						<Form onSubmit={props.handleSubmit}>
							<FormField
								name="deployment"
								component={Form.Input}
								componentProps={{
									label: 'Deployment',
									placeholder: 'bananastest',
								}}
								validate={validateRequired}
							/>
							<Form.Button type="submit" primary disabled={!props.isValid || props.isSubmitting}>
								Download
							</Form.Button>
						</Form>
					);
				}}
			/>
			<Divider hidden />*/}
			<Header as="h3">Reset password</Header>
			<Formik
				initialValues={{
					allStaff: false,
					deployment: 'bananastest',
					password: '',
					username: '',
				}}
				enableReinitialize={true}
				onSubmit={handleResetPassword}
				render={props => {
					const values: any = props.values || {};
					return (
						<Form onSubmit={props.handleSubmit}>
							<FormField
								name="deployment"
								component={Form.Input}
								componentProps={{
									label: 'Deployment',
									placeholder: 'bananastest',
								}}
								validate={validateRequired}
							/>
							<FormField
								name="username"
								component={Form.Input}
								componentProps={{
									disabled: values.allStaff,
									label: 'Staff username',
								}}
								validate={values.allStaff ? allowAll : validateRequired}
							/>
							<FormField
								name="allStaff"
								component={Form.Checkbox}
								componentProps={{
									label: 'All Staff',
								}}
							/>
							<FormField
								name="password"
								component={Form.Input}
								componentProps={{
									label: 'Password',
									type: 'password',
								}}
								validate={validateRequired}
							/>
							<Form.Button
								type="submit"
								primary
								disabled={!props.isValid || !(values.allStaff || values.username) || props.isSubmitting}
							>
								Reset password
							</Form.Button>
						</Form>
					);
				}}
			/>
		</>
	);

	function handleDownloadDeployment({ deployment }) {
		return controlStaging({
			staging,
			action: {
				type: actions.DOWNLOAD_DEPLOYMENT,
				deployment,
			},
		});
	}

	function handleResetPassword({ deployment, username, allStaff, password }) {
		if (allStaff) {
			return controlStaging({
				staging,
				action: {
					type: actions.RESET_ALL_PASSWORDS,
					deployment,
					password,
				},
			});
		} else {
			return controlStaging({
				staging,
				action: {
					type: actions.RESET_A_PASSWORD,
					deployment,
					username,
					password,
				},
			});
		}
	}
};

export default connect(
	null,
	{ controlStaging }
)(DeploymentTab);
