import React, { useCallback, useState } from 'react';
import { Header, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { FormField } from '../shared/FormField';
import { validateRequired } from '../../utils/validation';
import { actions } from '../../store/staging/actions/staging.constants';
import { controlStaging } from '../../store/staging/actions/staging.action';
import { connect } from 'react-redux';

const defaultTestRepository = {
	text: 'WebAppAutomationTests',
	value: actions.RUN_WEB_APP_AUTOMATION_TEST,
};

const apiTestRepository = {
	text: 'ApiAutomationTests',
	value: actions.RUN_API_AUTOMATION_TEST,
};

const testRepositories = [defaultTestRepository, apiTestRepository];

const testSuitesForRepository = {
	[actions.RUN_WEB_APP_AUTOMATION_TEST]: mapTestSuiteNamesIntoSelectOptions([
		'DiscountAndFeesTestSuite',
		'InvoicesTestSuite',
		'MiscTestSuite',
		'MoFPrimTestSuite',
		'PPPTestSuite',
		'COL_ALL-TESTS',
		'CustomLists/COL_CustomLists_AllTests',
		'CustomLists/CustomLists_ColumnConfig_DD',
		'CustomLists/CustomLists_Columns_DD',
		'CustomLists/CustomLists_FilterConfig_DD',
		'CustomLists/CustomLists_Filters_Company_DD',
		'CustomLists/CustomLists_Filters_Contact_DD',
		'CustomLists/CustomLists_Filters_Issues_DD',
		'CustomLists/CustomLists_Filters_Projects_DD',
		'CustomLists/CustomLists_Filters_Retainers_DD',
		'CustomLists/CustomLists_Filters_Sales_DD',
		'CustomLists/CustomLists_Filters_Tasks_DD',
		'CustomLists/CustomLists_Lists_DD',
		'CustomLists/CustomLists_non-data-driven-tests',
		'CustomLists/CustomLists_Sorting_DD',
		'CustomLists/CustomLists_StatusProgressions_DD',
	]),
	[actions.RUN_API_AUTOMATION_TEST]: mapTestSuiteNamesIntoSelectOptions([
		'COL_ALL-API-TESTS',
		'GraphQL_API/COL_GraphQL_All_Test_Suites',
		'Public_API/COL_ALL-Public-API-Tests',
		'Forms_API/Forms_API_TestSuite',
		'GraphQL_API/ActivitiesTestSuite_GQL',
		'GraphQL_API/SalesTestSuite_GQL',
		'GraphQL_API/TasksTestSuite_GQL',
		'GraphQL_API/TicketsTestSuite_GQL',
		'GraphQL_API/InvoicesTestSuite_GQL',
		'GraphQL_API/CustomLists_CompaniesTestSuite',
		'GraphQL_API/CustomLists_ContactsTestSuite',
		'GraphQL_API/CustomLists_Permissions_DD',
		'GraphQL_API/CustomLists_ProjectsTestSuite',
		'GraphQL_API/CustomLists_RetainersTestSuite',
		'GraphQL_API/CustomLists_SalesTestSuite',
		'GraphQL_API/CustomLists_TasksTestSuite',
		'GraphQL_API/EBIReports_Status_TestSuite',
		'GraphQL_API/EBIReports_Types_TestSuite',
		'Public_API/API_AssetTestSuite',
		'Public_API/API_ChecklistTestSuite',
		'Public_API/API_CompanyTestSuite',
		'Public_API/API_ContactsTestSuite',
		'Public_API/API_Contracts_Retainers_TestSuite',
		'Public_API/API_ContributorsTestSuite',
		'Public_API/API_DivisionTestSuite',
		'Public_API/API_GroupTestSuite',
		'Public_API/API_HolidayTestSuite',
		'Public_API/API_InvoicesTestSuite',
		'Public_API/API_LedgersTestSuite',
		'Public_API/API_PaymentsTestSuite',
		'Public_API/API_ProgressionTestSuite',
		'Public_API/API_RatesTestSuite',
		'Public_API/API_ReferralTestSuite',
		'Public_API/API_RequestTestSuite',
		'Public_API/API_SegmentationsTestSuite',
		'Public_API/API_TagsTestSuite',
		'Public_API/API_TaskPriorityTestSuite',
		'Public_API/API_TaxesTestSuite',
		'Public_API/API_TimerTestSuite',
		'Public_API/API_UserTestSuite',
	]),
};



const KatalonTab: React.FC<any> = function({ staging, controlStaging }) {
	const [availableTestSuites, setAvailableTestSuites] = useState(testSuitesForRepository[defaultTestRepository.value]);

	const updateAvailableTestSuites = useCallback(repositoryValue => {
		if (!repositoryValue) {
			return;
		}

		const updatedTestSuites = testSuitesForRepository[repositoryValue];
		if (!updatedTestSuites) {
			return;
		}
		setAvailableTestSuites(updatedTestSuites);
	}, []);

	const requestKatalonTestRun = useCallback(
		({ type, testSuiteName }) => {
			return controlStaging({
				staging,
				action: {
					type,
					testsuitename: testSuiteName,
				},
			});
		},
		[staging, controlStaging]
	);

	return (
		<>
			<Header as="h3">Run Katalon Tests</Header>
			<Formik
				initialValues={{
					type: defaultTestRepository.value,
					testSuiteName: testSuitesForRepository[defaultTestRepository.value][0].value,
				}}
				enableReinitialize={true}
				validateOnMount={true}
				onSubmit={requestKatalonTestRun}
			>
				{props => (
					<Form onSubmit={props.handleSubmit}>
						<FormField
							name="type"
							component={Form.Select}
							componentProps={{
								id: 'type',
								label: 'Repository',
								options: testRepositories,
							}}
							onChange={(_event, { value }) => updateAvailableTestSuites(value)}
							validate={validateRequired}
						/>
						<FormField
							name="testSuiteName"
							component={Form.Select}
							componentProps={{
								id: 'testSuiteName',
								label: 'Test Suite',
								options: availableTestSuites || [],
								search: true,
							}}
							validate={validateRequired}
						/>
						<Form.Button type="submit" primary disabled={!props.isValid || props.isSubmitting}>
							Run
						</Form.Button>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default connect(
	null,
	{ controlStaging }
)(KatalonTab);

function mapTestSuiteNamesIntoSelectOptions(testSuiteNames: string[]): { text: string; value: string }[] {
	return testSuiteNames.map(testSuiteName => ({ text: testSuiteName, value: testSuiteName }));
}
