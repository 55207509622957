import { stagingReducer } from './store/staging/reducers/staging.reducer';
import { IStaging } from './models/staging.interface';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as notificationReducer } from 'react-notification-system-redux';
import { authReducer, IState as AuthState } from './store/auth/reducers/auth.reducer';

export const reducers = {
	stagings: stagingReducer,
	notifications: notificationReducer,
	loadingBar: loadingBarReducer,
	auth: authReducer,
};

export interface IAppState {
	stagings: IStaging[];
	notifications: any;
	auth: AuthState;
}
