import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Header, Divider, Button, Form, Segment, Loader } from 'semantic-ui-react';
import { warning } from 'react-notification-system-redux';
import { Formik, useFormikContext } from 'formik';
import { connect, ReactReduxContext } from 'react-redux';
import { FormField } from '../shared/FormField';
import { validateRequired } from '../../utils/validation';
import { controlStaging } from '../../store/staging/actions/staging.action';
import { actions } from '../../store/staging/actions/staging.constants';
import { ContainerContext } from '../../utils/container.context';
import { IssueService } from '../../services/issues/issue.service';
import { Gravatar } from '../shared/Gravatar';

const BuildTab: React.FC<any> = function({ staging, controlStaging }) {
	const [isRebuildingGrunt, setIsRebuildingGrunt] = useState(false);
	const [isRestartingApache, setIsRestartingApache] = useState(false);
	return (
		<>
			<Header as="h3">Git branch</Header>
			<Formik
				initialValues={{
					branch: '',
					jiraKey: '',
				}}
				onSubmit={handleLoadBranch}
			>
				<BuildForm />
			</Formik>
			<Divider hidden />
			<Header as="h3">Grunt Build</Header>
			<Button primary onClick={handleRebuild} disabled={isRebuildingGrunt}>
				Rebuild
			</Button>
			<Divider hidden />
			<Header as="h3">Apache restart</Header>
			<Button primary onClick={handleApacheRestart} disabled={isRestartingApache}>
				Restart
			</Button>
		</>
	);

	function handleLoadBranch({ branch }) {
		return controlStaging({
			staging,
			action: {
				type: actions.LOAD_BRANCH,
				branch,
			},
		});
	}

	async function handleRebuild() {
		try {
			setIsRebuildingGrunt(true);
			await controlStaging({
				staging,
				action: {
					type: actions.BUILD_GRUNT,
				},
			});
		} finally {
			setIsRebuildingGrunt(false);
		}
	}

	async function handleApacheRestart() {
		try {
			setIsRestartingApache(true);
			await controlStaging({
				staging,
				action: {
					type: actions.RESTART_APACHE,
				},
			});
		} finally {
			setIsRestartingApache(false);
		}
	}
};

function BuildForm() {
	const container = useContext(ContainerContext);
	const { store } = useContext(ReactReduxContext);
	const [selectedIssue, setSelectedIssue] = useState<any>(null);
	const [selectedIssueKey, setSelectedIssueKey] = useState('');
	const [loadingIssue, setLoadingIssue] = useState(false);
	const form = useFormikContext<{ branch: string; jiraIssue: string }>();
	const selectedBranchCallback = useCallback(setSelectedBranch, []);
	useEffect(() => {
		(async () => {
			selectedBranchCallback('');
			setSelectedIssue(null);
			if (!selectedIssueKey) {
				return;
			}
			const issueService = container.get(IssueService);

			try {
				setLoadingIssue(true);
				const issueDetails = await issueService.getIssueDetails({ includeBranches: true, issues: [selectedIssueKey] });
				setSelectedIssue(issueDetails[selectedIssueKey]);
			} catch (e) {
				store.dispatch(
					warning({
						position: 'tr',
						title: 'Not found',
						message: `Issue ${selectedIssueKey} not found`,
					})
				);
				setSelectedIssue(null);
			} finally {
				setLoadingIssue(false);
			}
		})();
		return () => {
			setSelectedIssueKey('');
		};
	}, [selectedBranchCallback, container, selectedIssueKey, store]);

	function fetchJira(e) {
		if (e.target.value !== selectedIssueKey) {
			setSelectedIssueKey(e.target.value || '');
		}
	}

	function setSelectedBranch(selectedBranch) {
		form.setFieldValue('branch', selectedBranch, true);
	}

	return (
		<Form onSubmit={form.handleSubmit}>
			<Form.Group widths="equal">
				<FormField
					name="jiraKey"
					component={Form.Input}
					componentProps={{
						label: 'Jira Key',
						placeholder: 'AFFINITY-1234',
					}}
					onBlur={fetchJira}
				/>

				<FormField
					name="branch"
					component={Form.Input}
					componentProps={{
						label: 'Branch',
						placeholder: 'AFFINITY-1234-test',
						list: 'branches',
					}}
					onChange={(e, { value }) => setSelectedBranch(value)}
					validate={validateRequired}
				/>
				{selectedIssue && (
					<datalist id="branches">
						{selectedIssue.branches.map(branch => (
							<option value={branch} key={branch} />
						))}
					</datalist>
				)}
			</Form.Group>
			{loadingIssue && (
				<Loader size="mini" inline>
					Loading
				</Loader>
			)}
			{selectedIssue && (
				<Segment className="tertiary yellow inverted flex flex-align-center">
					{selectedIssue.assignee && (
						<span
							data-variation="mini"
							data-inverted=""
							data-tooltip={selectedIssue.assignee.displayName}
							className="image"
						>
							<Gravatar size={'mini'} circular email={selectedIssue.assignee.email} />
						</span>
					)}
					<span className="marg-left-10">{selectedIssue.summary}</span>
				</Segment>
			)}
			<Form.Button type="submit" primary disabled={!form.isValid || form.isSubmitting}>
				Load
			</Form.Button>
		</Form>
	);
}

export default connect(
	null,
	{ controlStaging }
)(BuildTab);
