import React from 'react';
import { Modal, Button, Tab } from 'semantic-ui-react';
import BuildTab from './BuildTab';
import DeploymentTab from './DeploymentTab';
import ElasticsearchTab from './ElasticsearchTab';
import PDFTab from './PDFTab';
import KatalonTab from './KatalonTab';

const ControlModal: React.FC<any> = function({ onClose = function() {}, popupState: { staging = '' } = {} }) {
	const panes = [
		{
			menuItem: 'Build',
			render: () => (
				<Tab.Pane>
					<BuildTab staging={staging} />
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Deployment',
			render: () => (
				<Tab.Pane>
					<DeploymentTab staging={staging} />
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Search',
			render: () => (
				<Tab.Pane>
					<ElasticsearchTab staging={staging} />
				</Tab.Pane>
			),
		},
		{
			menuItem: 'PDF',
			render: () => (
				<Tab.Pane>
					<PDFTab staging={staging} />
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Katalon',
			render: () => (
				<Tab.Pane>
					<KatalonTab staging={staging} />
				</Tab.Pane>
			),
		},
	];
	return (
		<>
			<Modal.Header>Staging {staging}</Modal.Header>
			<Modal.Content>
				<Tab menu={{ secondary: true, pointing: true }} panes={panes} />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => onClose()} negative>
					Close
				</Button>
			</Modal.Actions>
		</>
	);
};

export default ControlModal;
